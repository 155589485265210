import { getCartOverview } from "src/store/cartOverview";
import { customLog } from "../utils";
import { BOOKING } from "src/constants/booking";

export function getCartChangeListenerOpts(send) {
  return {
    type: "cartOverview/setCartOverview",
    effect: (action, listenerApi) => {
      customLog(action);

      const _newShoppingCart = getCartOverview(listenerApi.getState());
      const _oldShoppingCart = getCartOverview(listenerApi.getOriginalState());
      const _newActiveItems = _newShoppingCart.items.filter((i) => i.active);
      const _oldActiveItems = _oldShoppingCart.items.filter((i) => i.active);
      if (
        _newShoppingCart.total !== _oldShoppingCart.total ||
        _newShoppingCart.items.length !== _oldShoppingCart.items.length ||
        _newActiveItems.length !== _oldActiveItems.length ||
        _newActiveItems
          .map((i) => i.key + i.route + i.units)
          .some(
            (id, idx) =>
              id !==
              _oldActiveItems[idx].key +
                _oldActiveItems[idx].route +
                _oldActiveItems[idx].units
          )
      ) {
        send({ command: BOOKING.UPDATE_CART, payload: _newShoppingCart });
      }
    },
  };
}
