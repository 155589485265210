export const BOOKING = {
  CREATE_BOOKING: "create_booking",
  ADD_SERVICE_ANCILLARY: "add_service_ancillary",
  REMOVE_SERVICE_ANCILLARY: "remove_service_ancillary",
  ADD_SERVICE_ADDITIONAL: "add_service_additional",
  REMOVE_SERVICE_ADDITIONAL: "remove_service_additional",
  ADD_PASSENGER: "add_passenger",
  UPDATE_PASSENGER: "update_passenger",
  REMOVE_PASSENGER: "remove_passenger",
  UPDATE_CONTACT_INFO: "update_contact_info",
  ADD_BILLING_INFO: "add_billing_info",
  UPDATE_BILLING_INFO: "update_billing_info",
  REMOVE_BILLING_INFO: "remove_billing_info",
  CANCEL_ORDER: "cancel_order",
  PAYMENT_RECEIVED: "payment_received",
  COMMIT_BOOKING: "commit_booking",

  UPDATE_PAYMENT_TYPE: "update_payment_type",
  UPDATE_TERMS_ACCEPTANCE: "update_terms_acceptance",
  UPDATE_OFFERS_SUBSCRIPTION: "update_offers_subscription",
  UPDATE_NEWSLETTER_SUBSCRIPTION: "update_newsletter_subscription",
  CONTACT_DATA_COMPLETE: "contact_data_complete",
  MAIN_PASSENGER_COMPLETE: "main_passenger_complete",
  ALL_PASSENGERS_COMPLETE: "all_passengers_complete",
  UPDATE_CURRENT_STEP: "update_current_step",

  COMMIT_PAYMENT_LINK_PAGE: "commit_payment_link_page",
  UPDATE_TICKET_PRICES: "update_ticket_prices",
  LINK_USER_ID: "link_user_id",
  SET_DEVICE_TYPE: "set_device_type",

  UPDATE_SERVICE_PRICE: "update_service_price",
  UPDATE_IDENTITY_DOC: "update_identity_doc",
  REQUEST_TRIP_SUPPORT: "request_trip_support",
  UPDATE_CART: "update_cart",

  TICKET_PRICE_UPDATE: "ticket_price_update",
};
